import {BestellingWijzigenVerfieerDto} from "./bestelling-wijzigen-verfieer.dto";

export class BestellingWijzigenDto {
    bestellingId: string;
    straat: string;
    huisnummer: string;
    huisnummerToevoeging: string;
    bezorgdatum: string;
    postcode: string;
    verificatie: BestellingWijzigenVerfieerDto;
}