import {FactuurConceptDetailDto} from "./factuur-concept-detail.dto";

export class FactuurConceptDto {
    sellerName: string;
    sellerUuid: string;
    paymentDate: string;
    aantalOrders: number;
    totaalOrderBedrag: number;
    amountForSeller: number;
    totaalToeslag: number;
    deliveries: Array<FactuurConceptDetailDto>
}
