import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, ReplaySubject, share} from "rxjs";
import {ProfileUpdateDto} from "./profile-update.dto";
import {BASE_URL} from "../../app.constants";
import {NGXLogger} from "ngx-logger";
import {ProfileDto} from "./profile.dto";
import {catchError, tap} from "rxjs/operators";
import {AdamAuthService} from "../../auth/adam-auth.service";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    private SESSION_KEY = "PROFILE";
    subject = new ReplaySubject<ProfileDto>(1);
    private refreshing = false;

    constructor(private readonly httpClient: HttpClient, private readonly logger: NGXLogger) {

    }

    public getProfile(refresh: boolean = false): Observable<ProfileDto> {
        if (refresh === true && !this.refreshing) {
            sessionStorage.removeItem(this.SESSION_KEY);
            this.refreshing = true;
            this.httpClient.get<ProfileDto>(BASE_URL + 'portal/profile').pipe(
                share(),
                tap((it) => {
                    this.subject.next(it);
                }),
                catchError((e) => {
                    this.refreshing = false;
                    throw e;
                })).subscribe(() => {
                this.refreshing = false;
                this.logger.debug('Retrieved profile');
            })
        }
        return this.subject.asObservable();
    }


    public updateProfiel(profiel: ProfileUpdateDto): Observable<ProfileUpdateDto> {
        return this.httpClient.post<ProfileUpdateDto>(BASE_URL + 'portal/profile', profiel);
    }
}
