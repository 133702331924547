<div (click)="incrementClickCount()">
    <ng-container *ngIf="versionInfo?.tags">
        Version: {{versionInfo.tags}}
    </ng-container>
    <ng-container *ngIf="!versionInfo?.tags">
        Version: {{versionInfo?.commit}}
    </ng-container>

    <div *ngIf="showDiv">
        Date: {{versionInfo.commitDate}}<br/>
        Commit: {{versionInfo.commit}}<br/>
        Author: {{versionInfo.commitAuthor}}<br/>
        Message: {{versionInfo.commitMessage}}<br/>
    </div>
</div>