import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {SellerService} from "./seller.service";
import {mergeMap} from "rxjs/operators";
import {ProfileService} from "../profiel/profile.service";
import {SellerDto} from "./seller.dto";


@Injectable({
    providedIn: 'root'
})
export class SellerResolver implements Resolve<SellerDto> {
    constructor(private sellerService: SellerService, private profileService: ProfileService) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<SellerDto> {
        return this.profileService.getProfile().pipe(
            mergeMap(it => {
                if (it.sellerUuid) {
                    return this.sellerService.getVerkoper(it.sellerUuid);
                }
                return this.sellerService.getVerkoper(route.paramMap.get('id'));
            }));
    }
}
