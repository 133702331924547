import {Component, Input} from '@angular/core';
import {OrderDto} from "../../services/bestellingen/order.dto";

@Component({
    selector: 'adam-order-status',
    templateUrl: './order-status.component.html',
    styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent {

    @Input()
    order: OrderDto;


}
