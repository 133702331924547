export class OpeningstijdenDto {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    days: Array<any>
    anyDayOpen: boolean;
}

