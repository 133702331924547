import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AdamSpinnerService, AlertBarService, EmailFilter, EmailService} from "adam-common";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Router} from "@angular/router";

@Component({
    selector: 'portal-email-table',
    templateUrl: './email-table.component.html',
    styleUrls: ['./email-table.component.scss']
})
export class EmailTableComponent implements OnInit {
    emails: any

    EMAIL_SPINNER_NAME = 'email_table';

    _filter: EmailFilter;
    @Input()
    public set filter(input: EmailFilter) {
        this._filter = input;
        this.laad();
    };

    displayedColumns: string[] = ['aanmaakDatum', 'naar', 'onderwerp',  'verzonden', 'attempts'];
    content: any;
    pageEvent: PageEvent = {pageSize: 10, pageIndex: 0} as any;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    constructor(private readonly spinnerService: AdamSpinnerService,
                public readonly dialog: MatDialog,
                private router: Router,
                private readonly alertService: AlertBarService,
                private readonly emailService: EmailService) {
    }

    pageSizeOptions: number[] = [5, 10, 25, 100];


    ngOnInit(): void {
    }

    laad() {
        this.spinnerService.startSpinner(this.EMAIL_SPINNER_NAME);
        this.emailService.getEmails(this._filter, {
            pageSize: this.pageEvent.pageSize,
            pageNumber: this.pageEvent.pageIndex
        }).subscribe(response => {
                this.content = response;
                this.spinnerService.stopSpinner(this.EMAIL_SPINNER_NAME);
                this.emails = new MatTableDataSource(response.content);
                this.content.sort = this.sort;
                this.alertService.stopAlertbar(this.EMAIL_SPINNER_NAME)
            },
            () => {
                this.spinnerService.stopSpinner(this.EMAIL_SPINNER_NAME);
                this.alertService.startAlertbar(this.EMAIL_SPINNER_NAME, {message: 'Fout opgetreden', type: 'danger'})
            });
    }

    trigger($event: PageEvent) {
        this.pageEvent = $event;
        this.laad();
    }



    goto(row) {
        this.router.navigate(['portal', 'emails', row.uuid])
    }
}
