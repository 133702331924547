import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    constructor(private readonly httpClient: HttpClient) {
    }

    newRegistration(data): Observable<void> {
        return this.httpClient.post<void>(`${BASE_URL}open/register/new`, data);
    }

    completeRegistration(data): Observable<void> {
        return this.httpClient.post<void>(`${BASE_URL}open/register/complete`, data);
    }

    get(uuid: string): Observable<void> {
        return this.httpClient.get<void>(`${BASE_URL}open/register/${uuid}`);
    }

    exists(data): Observable<any> {
        return this.httpClient.post<any>(`${BASE_URL}open/register/exists`, data);
    }
}

