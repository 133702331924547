export class FactuurDto {
    invoiceId: string;
    amountForSeller: number;
    uuid: number;
    sellerName: string;
    sellerUuid: string;
    paidOut: boolean;
    creationDateTime: string;
    invoiceDate: string;
    paidDateTime: string;
    expectedPayDate: string;
}
