export class RequirementsDto {
    hasAtLeastOneStore: boolean;
    paymentAccountStatus: any;
    missingSellerInfo: Array<string>;
    sellerInfoComplete: boolean;
    sellerUuid: string;
    ready: boolean;
    paymentInfoComplete: boolean;
    readyForPayments: boolean;
    paymentsPendingVerification: boolean;
    paymentsInfoComplete: boolean;
    idVerificationNeeded: boolean;
}
