import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";
import {HttpClient} from "@angular/common/http";
import {BetaalGegevensDto} from "./betaal-gegevens.dto";

@Injectable({
    providedIn: 'root'
})
export class BetaalgegevensService {
    constructor(private readonly httpClient: HttpClient) {
    }

    externalProfile(sellerUuid: string): any {
        return this.httpClient.get<any>(`${BASE_URL}portal/sellers/${sellerUuid}/payment-profile/external`, {});
    }

    getPaymentProfileLink(sellerUuid: string): Observable<any> {
        return this.httpClient.get<BetaalGegevensDto>(`${BASE_URL}portal/sellers/${sellerUuid}/get-payment-profile-link`, {});
    }
}
