import {AddressDto} from "../../address/address.dto";

export class SellerDto {
    uuid: string;
    name: string;
    address: AddressDto;
    email: string;
    vatId: string;
    phonenumber: string;

}
