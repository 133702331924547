import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {adamEmailValidator} from "../../common/validator/adam-email.validator";

@Component({
    selector: 'adam-change-password-request',
    templateUrl: './change-password-request.component.html',
    styleUrls: ['./change-password-request.component.css']
})
export class ChangePasswordRequestComponent implements OnInit {

    resetForm: FormGroup;

    @Output() onSubmit = new EventEmitter<any>();


    constructor(
                private readonly _formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.resetForm = this._formBuilder.group({
            email: [undefined, [Validators.required, adamEmailValidator()]]
        });
    }

    nieuwRequest() {
        this.onSubmit.emit(this.resetForm.value.email);

    }
}
