import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashOrData'
})
export class DashOrDataPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === undefined || value === null || value === '') {
      return '-';
    }
    return value;
  }

}
