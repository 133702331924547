import {Directive, Input, Renderer2, TemplateRef, ViewContainerRef} from '@angular/core';
import {ProfileService} from "../services/profiel/profile.service";
import {map} from "rxjs/operators";
import {NGXLogger} from "ngx-logger";

@Directive({
    selector: '[hasPermissions]'
})

export class HasPermissionsDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
        private logger: NGXLogger,
        private readonly profileService: ProfileService
    ) {
    }

    @Input()
    set hasPermissions(requiredPermission: string[]) {

        this.profileService.getProfile().pipe(
            map(it => {
                return it.authorities.map(x => x);
            })).subscribe(it => {
            this.decideView(requiredPermission, it);

        });
    }

    decideView(requiredPermission: string[] | string, userPermission: string[]) {
        var changed;
        if (typeof requiredPermission === "string") {
            changed = [requiredPermission]
        } else {
            changed = requiredPermission
        }

        if (userPermission.some(it => changed.some(x => it === x))) {
            this.showComponent();
            this.logger.debug("Showing " + userPermission +  " - " + changed)

        } else {
            this.removeComponent();
        }
    }

    disableComponent(): void {
        this.viewContainerRef.clear();
        const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(
            this.templateRef
        ).rootNodes[0];
        viewRootElement.setAttribute('style', 'background-color: grey');
        this.renderer.setProperty(viewRootElement, 'disabled', true);
    }

    enableComponent(): void {
        this.viewContainerRef.clear();
        const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(
            this.templateRef
        ).rootNodes[0];
        this.renderer.setProperty(viewRootElement, 'disabled', false);
    }

    removeComponent(): void {
        this.viewContainerRef.clear();
    }

    showComponent(): void {
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(
            this.templateRef
        ).rootNodes[0];
    }


}
