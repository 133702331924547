<ng-container *ngIf="delivery">
     <span class="status status-{{delivery.status.toLowerCase()}}">
         <ng-container *ngIf="delivery.status.toLowerCase()==='invoiced'">
             {{delivery.status | bestellingStatus }} op {{delivery.invoiceExpectedPaymentDate| date: 'dd-MM-yyyy'}}
         </ng-container>
         <ng-container *ngIf="delivery.status.toLowerCase()==='paid_out'">
             {{delivery.status | bestellingStatus }} op {{delivery.invoicePaidDateTime| date: 'dd-MM-yyyy'}}
         </ng-container>
         <ng-container *ngIf="delivery.status.toLowerCase()==='delivered'">
             {{delivery.status | bestellingStatus }} op : {{delivery.invoiceDate | date: 'dd-MM-yyyy'}}
         </ng-container>
         <ng-container *ngIf="delivery.status.toLowerCase()==='unassigned'">
             {{delivery.status | bestellingStatus }}
         </ng-container>
         <ng-container *ngIf="delivery.status.toLowerCase()==='not_paid'">
             {{delivery.status | bestellingStatus }}
         </ng-container>
         <ng-container *ngIf="delivery.status.toLowerCase()==='not_confirmed'">
             {{delivery.status | bestellingStatus }} voor {{delivery.confirmationResponseDateTime | date :'dd-MM-yyy HH:mm'}}
         </ng-container>
         <ng-container *ngIf="delivery.status.toLowerCase()==='planned'">
             {{delivery.status | bestellingStatus }} op {{delivery.order.deliveryDate | date: 'dd-MM-yyyy'}}       tussen {{delivery.order.deliveryTime}}
         </ng-container>
         <ng-container *ngIf="delivery.status.toLowerCase()==='cancelled'">
             {{delivery.status | bestellingStatus }} op {{delivery.order.cancellation.cancellationDateTime | date: 'dd-MM-yyyy'}}
         </ng-container>

    </span>


</ng-container>
