import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderCancelReason'
})
export class OrderCancelReasonPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === 'test_order') {
      return 'Test order';
    }
    return value;
  }

}
