import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {lastValueFrom, Observable, of, ReplaySubject, share, switchMap, take, takeLast, takeUntil} from "rxjs";
import {BASE_URL} from "../../app.constants";
import {RequirementsDto} from "./requirements.dto";
import {catchError, map, tap} from "rxjs/operators";
import {RequirementTypes} from "./requirement-type.dto";
import {ProfileService} from "../profiel/profile.service";
import {untilDestroyed} from "@ngneat/until-destroy";

@Injectable({
    providedIn: 'root'
})
export class RequirementsService {

    subject = new ReplaySubject<any>(1);
    list = {}
    pending;

    constructor(private readonly httpClient: HttpClient, private readonly profileService: ProfileService) {
        this.subject.next(this.list);
    }2

    public getRequirements(sellerUuid: string, refresh = false): Observable<RequirementsDto> {
        return this.subject.asObservable().pipe(
            switchMap(it => {
                if (!this.list[sellerUuid]) {
                    if (!this.pending) {
                        let url = BASE_URL + 'portal/sellers/' + sellerUuid + '/requirements';
                        if (refresh) {
                            url = url + '/refresh'
                        }
                        this.pending = this.httpClient.get<RequirementsDto>(url).pipe(
                            share(),
                            tap(it => {
                                this.list[it.sellerUuid] = it;
                                this.subject.next(this.list);
                                this.pending = undefined;
                            }),
                            catchError((e) => {
                                this.list[sellerUuid] = undefined
                                throw e;
                            })
                        );
                    }
                    return this.pending;
                }
                return of(this.list[sellerUuid])
            }),
            map(() => {
                return this.list[sellerUuid];
            })
        );
    }

    public refreshRequirements(sellerUuid: string): Observable<RequirementsDto> {
        this.list[sellerUuid] = undefined;
        return this.getRequirements(sellerUuid, true);
    }


    hasCompleted(requirement: RequirementTypes): Observable<boolean> {
        return this.profileService.getProfile().pipe(
            switchMap(it => {
                if (it.sellerUuid) {
                    return this.getRequirements(it.sellerUuid);
                }
                return of({} as RequirementsDto);
            }),
            map(it => {
                if (requirement === "paymentInfo") {
                    return it.paymentInfoComplete;
                } else if (requirement === "sellerInfo") {
                    return it.sellerInfoComplete;
                } else if (requirement === "atLeastOneStore") {
                    return it.hasAtLeastOneStore;
                }
                return false;
            }),
            take(1)
        );

    }

    isPaymentAccountDisabled() {
        return this.profileService.getProfile().pipe(
            switchMap(it => {
                if (it.sellerUuid) {
                    return this.getRequirements(it.sellerUuid);
                }
                return of({} as RequirementsDto);
            }),
            map(it => {
                return it.paymentAccountStatus.status==='DISABLED';
            }),
        );
    }
}


