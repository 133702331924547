import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BASE_URL} from "../../../app.constants";
import {BestellingWijzigenMogelijkDto} from "./bestelling-wijzigen-mogelijk.dto";
import {BestellingWijzigenDto} from "./bestelling-wijzigen.dto";
import {BestellingWijzigenVerfieerDto} from "./bestelling-wijzigen-verfieer.dto";

@Injectable({
    providedIn: 'root'
})
export class BestellingWijzigingService {

    constructor(private readonly httpClient: HttpClient) {
    }

    verifieerBestelling(dto: BestellingWijzigenVerfieerDto): Observable<BestellingWijzigenMogelijkDto> {
        return this.httpClient.post<BestellingWijzigenMogelijkDto>(`${BASE_URL}open/bestellingen/verifieer`, dto);
    }

    wijzigBestelling(dto: BestellingWijzigenDto): Observable<void> {
        return this.httpClient.post<void>(`${BASE_URL}open/bestellingen/wijzigen`, dto);
    }

    annuleerBestelling(bestellingId: string): Observable<void> {
        return this.httpClient.post<void>(`${BASE_URL}open/bestellingen/annuleren`, {bestellingId});
    }
}
