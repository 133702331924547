import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from '../../app.constants';
import {EmailTemplateDto} from "./email-template.dto";

@Injectable({
    providedIn: 'root'
})
export class EmailTemplateService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getTemplates(): Observable<any> {
        return this.httpClient.get<Array<EmailTemplateDto>>(`${BASE_URL}portal/email-templates`);
    }

    getTemplate(id: number): Observable<any> {
        return this.httpClient.get<EmailTemplateDto>(`${BASE_URL}portal/email-templates/${id}`);
    }



}
