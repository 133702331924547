import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AngularFireAuthGuard, redirectUnauthorizedTo} from "@angular/fire/compat/auth-guard";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'refresh', loadChildren: () => import('./refresh-requirements/refresh-requirements.module').then(m => m.RefreshRequirementsModule)},
    {path: 'login', loadChildren: () => import('./auth/portal-login.module').then(m => m.PortalLoginModule)},
    {
        path: 'register',
        loadChildren: () => import('./auth/registreer/portal-registreer.module').then(m => m.PortalRegistreerModule)
    },
    {
        path: 'portal',
        canActivate: [AngularFireAuthGuard],
         data: { authGuardPipe: redirectUnauthorizedToLogin },
        loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule)
    },
    {path: '**', redirectTo: 'portal'}
];


@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            onSameUrlNavigation: 'reload',
         //   enableTracing: true //<--debug only
        })],
    exports: [RouterModule],
})
export class PortalAppRoutingModule {
}
