import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'adam-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnInit {

    @Input()
    public text;
    @Input()
    public position = 'right';

    constructor() {
    }

    ngOnInit(): void {
    }

}
