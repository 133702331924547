import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of, ReplaySubject, share, switchMap, take} from "rxjs";
import {SellerDto} from "./seller.dto";
import {BASE_URL} from '../../app.constants';
import {NieuweVerkoperDto} from "./nieuwe-verkoper.dto";
import {catchError, tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SellerService {

    private cachedRegistrations: NieuweVerkoperDto[] | null = null;
    private isRequestPending = false;
    private registrationsSubject = new ReplaySubject<NieuweVerkoperDto[] | null>();


    constructor(private readonly httpClient: HttpClient) {
    }

    getVerkopers(): Observable<Array<SellerDto>> {
        return this.httpClient.get<Array<SellerDto>>(`${BASE_URL}portal/sellers`);
    }

    getVerkoper(sellerUuid: string): Observable<SellerDto> {
        return this.httpClient.get<SellerDto>(`${BASE_URL}portal/sellers/${sellerUuid}`);
    }

    getRegistrations(refresh: boolean = false): Observable<NieuweVerkoperDto[]> {
        if (!refresh && this.cachedRegistrations !== null) {
            // Return cached data if available and refresh is not requested
            return this.registrationsSubject.asObservable().pipe(take(1));
        }

        if (!this.isRequestPending) {
            // If no request is currently pending, make a new HTTP call
            this.isRequestPending = true;

            let url = `${BASE_URL}portal/registrations/pending-approval`;

            this.httpClient.get<NieuweVerkoperDto[]>(url).subscribe(
                (data) => {
                    // Update the cached data and notify subscribers
                    this.cachedRegistrations = data;
                    this.registrationsSubject.next(data);
                },
                (error) => {
                    console.error(error)
                },
                () => {
                    // Mark the request as not pending when it completes (success or error)
                    this.isRequestPending = false;
                }
            );
        }

        return this.registrationsSubject.asObservable().pipe(take(1));
    }

    initialiseer(verkoperId: number): Observable<Array<NieuweVerkoperDto>> { //TODO: This can be a subject
        return this.httpClient.post<Array<NieuweVerkoperDto>>(`${BASE_URL}portal/registrations/${verkoperId}/approve`, {}).pipe(
            tap(it=>{
                this.cachedRegistrations = it;
                this.registrationsSubject.next(it);
            })
        );
    }

    verstuurMorgenMail(verkoperUuid: string): Observable<any> {
        return this.httpClient.post<void>(`${BASE_URL}admin/sellers/${verkoperUuid}/send-tomorrow-mail`, {});
    }


    public updateSeller(id, data: any): Observable<any> {
        return this.httpClient.post(BASE_URL + 'portal/sellers/' + id, data)
    }


}
