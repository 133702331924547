import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {PostcodeSimpelDto} from "./postcode-simpel.dto";
import {HttpClient} from "@angular/common/http";
import {Cacheable} from 'ts-cacheable';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {PostcodeBestaatValidator} from "../../common/validator/existing-postalcode-validator";
import {BASE_URL} from "../../app.constants";

@Injectable({
    providedIn: 'root'
})
export class PostcodeService {

    URL = "https://postcode.tech/api/v1/postcode?postcode="
    TOKEN = '87c444eb-e3cf-449b-8f82-6b9a9da688da'
    readonly CITY = 'city';
    readonly STREET = 'street';
    readonly HOUSENUMBER = 'housenumber';
    readonly POSTALCODE = 'postalcode'
    readonly HOUSENUMBER_SUFFIX = 'housenumberSuffix'

    constructor(private readonly httpClient: HttpClient) {
    }

    @Cacheable()
    public getAdresSimpel(postcode: string, huisnummer: number): Observable<PostcodeSimpelDto> {
        if (postcode && huisnummer) {
            postcode = postcode.replace(/ /g, '');
            return this.httpClient.post<PostcodeSimpelDto>(BASE_URL + `open/postalcode?postalcode=${postcode}&housenumber=${huisnummer}`,{})
        }
        throw new Error("Aangeroepen zonder complete params");
    }

    public updateForm(addressForm){
        addressForm.get(this.POSTALCODE).setAsyncValidators(PostcodeBestaatValidator.createValidator(addressForm.get(this.POSTALCODE), addressForm.get(this.HOUSENUMBER), this))
        addressForm.get(this.STREET).disable()
        addressForm.get(this.CITY).disable()
        addressForm.get(this.POSTALCODE).valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(() => {
                addressForm.get(this.CITY).setValue(undefined);
                addressForm.get(this.STREET).setValue(undefined);
            });
        addressForm.get(this.HOUSENUMBER).valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(() => {
                addressForm.get(this.CITY).setValue(undefined);
                addressForm.get(this.STREET).setValue(undefined);
                addressForm.get(this.POSTALCODE).updateValueAndValidity({emitEvent: true});
            });
        addressForm.get(this.POSTALCODE).statusChanges.subscribe(it => {
            if (it === 'VALID') {
                this.getAdresData(addressForm, addressForm.get(this.POSTALCODE).value, addressForm.get(this.HOUSENUMBER).value);
            }
        })
    }
    getAdresData(addressForm, postcode, huisnummer) {

        addressForm.get('city').setValue(undefined);
        addressForm.get('street').setValue(undefined);
        if (addressForm.get('housenumber').valid && addressForm.get('postalcode').valid) {
            this.getAdresSimpel(postcode, huisnummer).subscribe(result => {
                addressForm.get('city').setValue(result.city);
                addressForm.get('street').setValue(result.street);
            })
        }
    }
}
