import {Inject, Injectable} from '@angular/core';

import {Router} from "@angular/router";
import {firstValueFrom, mergeMap, Observable, Subject, throwError} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient} from "@angular/common/http";
import {BASE_URL} from "../app.constants";
import {map, tap} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class AdamAuthService {

    private subject = new Subject();

    constructor(private router: Router,
                private httpClient: HttpClient,
                private readonly logger: NGXLogger,
                @Inject('LOGINURL') private readonly loginUrl: string,
                private readonly afAuth: AngularFireAuth) {
    }


    getUsername(): Promise<string> {
        return this.afAuth.currentUser.then((user) => {
            return user?.email;
        })
    }

    onLogout() {
        return this.subject.asObservable();
    }

    public checkCode(code: string): Observable<any> {
        return this.httpClient.post(
            BASE_URL + 'open/auth/verify-password-reset-code',
            {
                code,
            }
        )
    }

    isLoggedIn(): Promise<boolean> {
        return firstValueFrom(this.afAuth.idToken.pipe(map(it=>it!==null),
            tap(it=> {
                if(!it){
                    this.logger.info("User is not logged in")
                }
            })));
    }


    idToken(): Observable<string> {
        return this.afAuth.idToken;
    }

    verstuurWachtwoordResetMail(email: string): Observable<any> {
        this.logger.debug('Requesting password reset: ' + email);
        return this.httpClient.post(
            BASE_URL + 'open/auth/reset-password-request',
            {
                email,
            }
        )
    }

    changePassword(code: string, newPassword: string, newPasswordConfirm: string) {
        return this.httpClient.post(
            BASE_URL + 'open/auth/reset-password',
            {
                code,
                newPassword,
                newPasswordConfirm
            }
        )
    }

    authenticate(username: string, password: string): Observable<any> {
        return this.httpClient.post(
            BASE_URL + 'open/auth/sign-in',
            {
                username,
                password,
            }
        ).pipe(
            mergeMap(it => {
                if (it['result'] == 'OK') {
                    return this.afAuth.signInWithCustomToken(it['token'])
                }
                return throwError(it['result']);
            })
        );
    }

    hasPermissie(rollen: string[]): Promise<boolean> {
        return this.afAuth.currentUser.then((user) => {
            return user?.getIdTokenResult();
        }).then(token => {
            if (token) {
                return rollen.some(it => token?.claims[it] === true);
            }
            return false;
        })
    }

    logout(): Promise<boolean> {
        this.logger.debug('Logging out user');
        sessionStorage.clear();
        this.subject.next({});
        return this.afAuth.signOut().then(() => {
            return this.router.navigate([this.loginUrl]);
        });
    }
}
