<form [formGroup]="resetForm" class="custom-form">
    <mat-form-field class="w-100">
        <input matInput [type]="hide ? 'password' : 'text'" name="wachtwoord" autocomplete="" formControlName="wachtwoord" placeholder="Wachtwoord">

        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="resetForm.get('wachtwoord').hasError('hasNumber')">Het wachtwoord moet minimaal 1 cijfer
            bevatten
        </mat-error>
        <mat-error *ngIf="resetForm.get('wachtwoord').hasError('required')">Dit veld is verplicht</mat-error>
        <mat-error *ngIf="
        !resetForm.get('wachtwoord').hasError('hasNumber') &&
        resetForm.get('wachtwoord').hasError('hasCapitalCase')">
            Het wachtwoord moet minimaal hoofdletter bevatten
        </mat-error>
        <mat-error *ngIf="
        !resetForm.get('wachtwoord').hasError('hasNumber') &&
        !resetForm.get('wachtwoord').hasError('hasCapitalCase') &&
        resetForm.get('wachtwoord').hasError('hasSmallCase')">
            et wachtwoord moet minimaal kleine letter bevatten
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
        <input matInput [type]="hide ? 'password' : 'text'" name="herhaalWachtwoord" formControlName="herhaalWachtwoord"  autocomplete=""
               placeholder="Herhaal wachtwoord">
        <mat-error *ngIf="resetForm.get('herhaalWachtwoord').hasError('mustMatch')">De wachtwoorden zijn niet gelijk
        </mat-error>
        <mat-error *ngIf="resetForm.get('wachtwoord').hasError('required')">Dit veld is verplicht</mat-error>
    </mat-form-field>
    <button type="submit" mat-raised-button color="primary" class="w-100" (click)="wijzigen()">{{buttonText}}
    </button>
</form>
