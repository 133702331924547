import {Component, Input, OnInit} from '@angular/core';
import {RequirementsService} from "adam-common";
import {RequirementTypes} from "../../services/requirements/requirement-type.dto";
import {Observable} from "rxjs";

@Component({
  selector: 'portal-has-completed-requirement',
  templateUrl: './has-requirement.component.html'
})
export class HasRequirementComponent implements OnInit {

  @Input()
  requirement: RequirementTypes;

  public hasCompletedRequirement: Observable<boolean>;

  constructor(private readonly requirmentService: RequirementsService) {
  }

  ngOnInit(): void {
    this.hasCompletedRequirement = this.requirmentService.hasCompleted(this.requirement);
  }

}
