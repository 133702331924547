import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {BASE_URL} from "../../app.constants";
import {AdamAuthService} from "../../auth/adam-auth.service";
import {NGXLogger} from "ngx-logger";

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private readonly auth: AdamAuthService, private readonly logger: NGXLogger) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf("adam")!==-1 && !request.url.startsWith(BASE_URL + "open")) {
            return this.auth.idToken().pipe(
                mergeMap((token: any) => {
                    if (token && !this.isTokenExpired(token)) {
                        request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                    }
                    return next.handle(request);


                }));

        }
        return next.handle(request);
    }

    private isTokenExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp *1000;
        this.logger.trace('Token expires at', new Date(expiry))
        const expired = expiry < Date.now();
        if(expired){
            this.logger.info('Token expired ', expired)
        }
        return expired;
    }


}
