export const environment = {
    firebase: {
        projectId: 'adam-72e92',
        appId: '1:929909036987:web:89f7ea234c82728e50169e',
        storageBucket: 'adam-72e92.appspot.com',
        apiKey: 'AIzaSyAxlRgavdl2HuR2ebN3n_mJfAKsDAvpryw',
        authDomain: 'adam-72e92.firebaseapp.com',
        messagingSenderId: '929909036987',
        measurementId: 'G-M26HYK8F8T',
    },
    production: false
};

