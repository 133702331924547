import { AbstractControl, ValidationErrors } from '@angular/forms';

export const dutchPostalcodeValidator = (function () {

    const postcode =  /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
    const whitspace = /\s+/g;

    return (postalcodeControl: AbstractControl): ValidationErrors | null => {

        if (whitspace.test(postalcodeControl.value)) {
            const trimmedValue: string = postalcodeControl.value.toUpperCase().replace(whitspace, '');
            postalcodeControl.setValue(trimmedValue);
        }
        if (!postcode.test(postalcodeControl.value)) {
            return { invalid: true };
        }
        return null;
    };
}());
