import {Component, Input} from '@angular/core';
import {BestellingDto} from "../../services/bestellingen/bestelling.dto";

@Component({
    selector: 'adam-bestelling-status',
    templateUrl: './bestelling-status.component.html',
    styleUrls: ['./bestelling-status.component.scss']
})
export class BestellingStatusComponent {

    @Input()
    delivery: BestellingDto;


}
