<adam-info *ngIf="content?.numberOfElements===0">Geen emails aanwezig</adam-info>
<ng-container *ngIf="content?.numberOfElements>0">
    <table mat-table [dataSource]="emails" class="w-100">
        <ng-container matColumnDef="attempts">
            <th mat-header-cell *matHeaderCellDef> Pogingen</th>
            <td mat-cell *matCellDef="let element">
                {{element.attempts}}
            </td>
        </ng-container>
        <ng-container matColumnDef="onderwerp">
            <th mat-header-cell *matHeaderCellDef> Onderwerp</th>
            <td mat-cell *matCellDef="let element"> {{element.onderwerp}} </td>
        </ng-container>
        <ng-container matColumnDef="naar">
            <th mat-header-cell *matHeaderCellDef> Naar</th>
            <td mat-cell *matCellDef="let element"> {{element.naar}} </td>
        </ng-container>
        <ng-container matColumnDef="verzonden">
            <th mat-header-cell *matHeaderCellDef> Verstuurd</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon class="red" *ngIf="!element.verzonden && !element.accepted">block</mat-icon>
                <mat-icon class="green" *ngIf="element.verzonden">check_circle</mat-icon>
                <mat-icon class="green" *ngIf="element.accepted">block</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="aanmaakDatum">
            <th mat-header-cell *matHeaderCellDef> Datum</th>
            <td mat-cell *matCellDef="let element"> {{element.aanmaakDatum}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goto(row)" class="clickable"></tr>
    </table>

    <mat-paginator [length]="content?.totalElements"
                   [pageSize]="pageEvent.pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="trigger($event)"
                   aria-label="Select page">
    </mat-paginator>

</ng-container>
