import {Injectable} from '@angular/core';


import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {filter, map} from 'rxjs/operators';
import {ConfiguratieService} from "../services/configuratie/configuratie.service";
import {ConfiguratieEnum} from "../services/configuratie/configuratie.enum";

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private configService: ConfiguratieService
    ) {
    }

    init() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.router)
            )
            .subscribe((event) => {
                    let title = this.getTitle(this.activatedRoute, this.router.url);
                    const appName = this.configService.getConfig(ConfiguratieEnum.APP_NAME);
                    if (appName) {
                        title = title + " | " + appName;
                    }
                    this.titleService.setTitle(title);
                }
            );
    }

    getTitle(route: ActivatedRoute, url: String) {
        if (route.snapshot.data['title']) {
            return route!.snapshot.data['title'];
        }
        const parts = url.split("/")
        return this.capitalizeFirstLetter(parts[parts.length - 1].toLowerCase());
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


}
