import {BestellingDto} from "adam-common";

export interface UpcomingDeliveryDto {
    storeUuid: string;
    storeName: string;
    nextOpeningDate: string;
    mailSent: boolean;
    deliveries: Array<BestellingDto>
    havingUpcomingDeliveries: boolean;
}


