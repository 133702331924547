import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BeschikbaarheidOverzichtDto} from "./beschikbaarheid-overzicht.dto";
import {BASE_URL} from "../../../app.constants";

@Injectable({
    providedIn: 'root'
})
export class BeschikbaarheidService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getBeschikbaarheid(postcode: string): Observable<BeschikbaarheidOverzichtDto> {
        return this.httpClient.get<BeschikbaarheidOverzichtDto>(`${BASE_URL}open/availability?postcode=${postcode}`);
    }

    getOverview(): Observable<any> {
        return this.httpClient.get<BeschikbaarheidOverzichtDto>(`${BASE_URL}open/availability/overview`);
    }

}
