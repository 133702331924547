import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(private readonly httpClient: HttpClient) {
    }


    refresh(orderUuid: String): Observable<any> {
        return this.httpClient.get<any>(`${BASE_URL}portal/payments/${orderUuid}/refresh`);
    }
}

