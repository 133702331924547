import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from '../../app.constants';
import {EmailDto} from "./email.dto";
import {EmailFilter} from "./email-filter.dto";

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private readonly httpClient: HttpClient) {
    }
    get(uuid): Observable<EmailDto> {
        return this.httpClient.get<any>(`${BASE_URL}portal/emails/${uuid}`, {})
    }

    getAttachment(attachmentId): Observable<Blob> {
        return this.httpClient.get(`${BASE_URL}/portal/emails/attachments/${attachmentId}`, {responseType: 'blob'});
    }

    retry(uuid: any): Observable<any> {
        return this.httpClient.post<any>(`${BASE_URL}portal/emails/${uuid}/retry`, {})
    }
    accept(uuid: any): Observable<any> {
        return this.httpClient.post<any>(`${BASE_URL}portal/emails/${uuid}/accept-not-sent`, {})
    }

    getEmails(filter: EmailFilter, page: {pageNumber: number; pageSize: number}) {
        return this.httpClient.post<any>(`${BASE_URL}portal/emails?page=${page.pageNumber}&size=${page.pageSize}`, filter);

    }
}
