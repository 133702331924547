import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";
import {SysteemStatusDto} from "./systeem-status.dto";

@Injectable({
    providedIn: 'root'
})
export class SysteemstatusService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getSysteemStatus(): Observable<SysteemStatusDto> {
        return this.httpClient.get<SysteemStatusDto>(`${BASE_URL}portal/systeemstatus`);
    }
}
