import {OrderDto} from "./order.dto";

export class BestellingDto {
    uuid: string;
    order: OrderDto;
    storeName: string;
    assigned: boolean;
    assignmentConfirmed: boolean;
    assignmentConfirmedDateTime: string;
    confirmationResponseDateTime: string;
    assignmentConfirmedBy: string;
    sellerName: string;
    storeUuid: string;
    invoiceId: string;
    sellerUuid: string;
    bezorgdatum: string;
    bezorgtijd: string;
    besteldDoor: string;
    aanmaakdatum: string;
    verkoperNaam: string;
    bezorgAdres: string;
    invoiceDate: string;
    invoicePaidDateTime: string;
    invoiceExpectedPaymentDate: string;
    delivered: boolean;
    status: string;
    packageSlipAvailable: boolean;
    assignmentConfirmationPossible: boolean;
    confirmationPossibleByAdmin: boolean;
    tomorrowDeliveryEmailScheduled: string;
    tomorrowDeliveryEmailSentDateTime: string;
}
