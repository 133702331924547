import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import { BASE_URL } from '../../app.constants';
import {HttpClient} from "@angular/common/http";
import {SamenvattingDto} from "./samenvatting.dto";

@Injectable({
  providedIn: 'root'
})
export class SamenvattingService {

  constructor(private readonly httpClient: HttpClient) {
  }

  getSamenvatting(): Observable<SamenvattingDto> {
    return this.httpClient.get<SamenvattingDto>(`${BASE_URL}portal/samenvatting`);
  }
}
