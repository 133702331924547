import {Component, Input, OnInit} from '@angular/core';
import {AlertBarService} from "./alert-bar.service";
import {AlertStatusDto} from "./alert-status.dto";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'adam-alert-bar',
    templateUrl: './alert-bar.component.html',
    styleUrls: ['./alert-bar.component.scss']
})
export class AlertBarComponent implements OnInit {
    private _name: any;
    public show = false;
    public data: AlertStatusDto;

    constructor(private readonly alertbarService: AlertBarService) {
    }

    @Input()
    set name(val) {
        if (val) {
            this._name = val;
            this.alertbarService.registerAlertbar(this._name);
        }
    }

    ngOnInit(): void {
        this.alertbarService.getStatus(this._name)
            .pipe(untilDestroyed(this))
            .subscribe((it) => {
            this.data = it;
        });
    }
}
