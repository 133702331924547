import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SimpleModalDto} from "./simple-modal.dto";

@Component({
    selector: 'adam-simple-modal',
    templateUrl: './simple-modal.component.html'
})
export class SimpleModalComponent implements OnInit {

    @Output() onOk = new EventEmitter<void>();
    @Output() onCancel = new EventEmitter<void>();

    constructor(
        public dialogRef: MatDialogRef<SimpleModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SimpleModalDto
    ) {
    }

    ngOnInit(): void {
    }

    cancel() {
        this.onCancel.emit();
    }

    ok() {
        this.onOk.emit();
    }

}
