import {AsyncValidatorFn, FormGroup, ValidationErrors} from "@angular/forms";
import {AdamAuthService} from "./adam-auth.service";
import {RegisterService} from "../services/register/register.service";
import {lastValueFrom} from "rxjs";

export class CocNumberExistsValidator {
    static createValidator(registerService: RegisterService): AsyncValidatorFn {
        return (control: FormGroup): Promise<ValidationErrors> => {
            return new Promise(async (resolve, reject) => {
                if (control.value) {
                    const obs = registerService.exists({coc: control.value});
                    const result = await lastValueFrom(obs);
                    if (result.includes("COC_PENDING_INVITATION")) {
                        resolve({invitation_exists: true})
                    } else if (result.includes( "COC_EXISTS")) {
                        resolve({exists: true})
                    } else {
                        resolve(null)
                    }
                } else {
                    resolve(null);
                }
            });
        };
    }
}
