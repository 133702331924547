import {NgModule} from '@angular/core';
import {ErrorComponent} from "./common/error/error.component";
import {InfoComponent} from "./common/info/info.component";
import {AppNameComponent} from "./common/app-name/app-name.component";
import {CommonModule} from "@angular/common";
import {MatChipsModule} from "@angular/material/chips";
import {SimpleModalComponent} from './common/simple-modal/simple-modal.component';
import {MatButtonModule} from "@angular/material/button";
import {AdamWhenTestmodeComponent} from './common/adam-when-testmode/adam-when-testmode.component';
import {TestBannerComponent} from './common/test-banner/test-banner.component';
import {TooltipComponent} from './common/tooltip/tooltip.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {BestellingStatusPipe} from './common/bestelling-status.pipe';
import {AlertBarComponent} from './common/alert-bar/alert-bar.component';
import {SuccessComponent} from './common/success/success.component';
import {FocusOnShowDirective} from "./common/focus-on-show.directive";
import {PrettyjsonPipe} from "./common/pretty-json.pipe";
import {BestellingStatusComponent} from './common/bestelling-status/bestelling-status.component';
import {ViewPopupComponent} from "./common/view-popup/view-popup.component";
import {OpeningdaysViewComponent} from "./common/opening-days-view/openingdays-view.component";
import {CrudButtonComponent} from "./common/crud-button/crud-button.component";
import {OrderStatusComponent} from "./common/order-status/order-status.component";
import { VersionComponent } from './common/version/version.component';
import {WarningComponent} from "./common/warning/warning.component";
import { TrimDirective } from './common/trim.directive';
import {PaymentProfileTestIbanComponent} from "./common/payment-profile-test-iban/payment-profile-test-iban.component";
import { HasRequirementComponent } from './requirement/has-requirement/has-requirement.component';
import { HasOpenRequirementComponent } from './requirement/has-open-requirement/has-open-requirement.component';
import { IsPaymentAccountDisabledComponent } from './requirement/is-payment-account-disabled/is-payment-account-disabled.component';
import { IsPaymentAccountNotDisabledComponent } from './requirement/is-payment-account-not-disabled/is-payment-account-not-disabled.component';
import { DashOrDataPipe } from './dash-or-data.pipe';
import {EmailTableComponent} from "./email/email-table/email-table.component";

import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {OrderCancelReasonPipe} from "./order-cancel-reason.pipe";

@NgModule({
    declarations: [
        AdamWhenTestmodeComponent,
        ErrorComponent,
        InfoComponent,
        FocusOnShowDirective,
        AppNameComponent,
        PaymentProfileTestIbanComponent,
        WarningComponent,
        CrudButtonComponent,
        ViewPopupComponent,
        OrderStatusComponent,
        SimpleModalComponent,
        AdamWhenTestmodeComponent,
        TestBannerComponent,
        TooltipComponent,
        BestellingStatusPipe,
        OpeningdaysViewComponent,
        PrettyjsonPipe,
        AlertBarComponent,
        EmailTableComponent,
        SuccessComponent,
        BestellingStatusComponent,
        VersionComponent,
        TrimDirective,
        HasRequirementComponent,
        HasOpenRequirementComponent,
        IsPaymentAccountDisabledComponent,
        IsPaymentAccountNotDisabledComponent,
        DashOrDataPipe,
        OrderCancelReasonPipe,
    ],
    exports: [
        FocusOnShowDirective,
        ErrorComponent,
        InfoComponent,
        MatIconModule,
        TestBannerComponent,
        EmailTableComponent,
        OpeningdaysViewComponent,
        AdamWhenTestmodeComponent,
        PaymentProfileTestIbanComponent,
        AppNameComponent,
        SuccessComponent,
        ViewPopupComponent,
        BestellingStatusPipe,
        TooltipComponent,
        PrettyjsonPipe,
        CrudButtonComponent,
        OrderStatusComponent,
        AlertBarComponent,
        HasRequirementComponent,
        HasOpenRequirementComponent,
        BestellingStatusComponent,
        VersionComponent,
        WarningComponent,
        TrimDirective,
        IsPaymentAccountDisabledComponent,
        IsPaymentAccountNotDisabledComponent,
        DashOrDataPipe,
        OrderCancelReasonPipe
    ],
    imports: [
        MatButtonModule,
        CommonModule,
        MatChipsModule,
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatTooltipModule
    ]
})
export class AdamCommonComponentsModule {
}
