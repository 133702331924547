import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";
import {EmployeeOfStoreDto} from "./employee-of-store.dto";

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {


    constructor(private readonly httpClient: HttpClient) {

    }

    public acceptInvite(inviteUuid: string, password: String): Observable<any> {
        return this.httpClient.post<Array<EmployeeOfStoreDto>>(BASE_URL + 'open/invites/accept/' + inviteUuid, {password})

    }

    public getEmployees(storeUuid: string): Observable<Array<EmployeeOfStoreDto>> {
        return this.httpClient.get<Array<EmployeeOfStoreDto>>(BASE_URL + 'portal/stores/' + storeUuid + '/employees')
    }

    public deleteEmployee(storeUuid: string, userUuid: string): Observable<any> {
        return this.httpClient.get<Array<EmployeeOfStoreDto>>(BASE_URL + 'portal/stores/' + storeUuid + '/employees/' + userUuid)
    }

    public deleteInvite(storeUuid: string, inviteUuid: string): Observable<any> {
        return this.httpClient.delete(`${BASE_URL}portal/stores/${storeUuid}/invites/${inviteUuid}`);
    }

    public invite(storeUuid: string, email: string): Observable<Array<EmployeeOfStoreDto>> {
        return this.httpClient.put<Array<EmployeeOfStoreDto>>(BASE_URL + 'portal/stores/' + storeUuid + '/employees', {email: email})
    }

}
