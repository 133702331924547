import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {AdamCommonComponentsModule, SpinnerModule, TitleService} from 'adam-common';
import {environment} from '../environments/environment';
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {PortalAppRoutingModule} from "./portal-app-routing.module";
import {PortalAppComponent} from "./portal-app.component";
import {AngularFireAuthGuardModule} from "@angular/fire/compat/auth-guard";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ServicesModule} from "./portal/services.module";

import localeNl from '@angular/common/locales/nl';
import {registerLocaleData} from "@angular/common";
import {AppInitializerService} from "./app-initializer.service";

registerLocaleData(localeNl);

@NgModule({
    declarations: [
        PortalAppComponent,
    ],
    imports: [
        PortalAppRoutingModule,
        LoggerModule.forRoot({
            level: NgxLoggerLevel.TRACE //TODO: Dev and prod
        }),
        BrowserModule,
        BrowserAnimationsModule,
        SpinnerModule,
        ServicesModule.forRoot(),
        FlexLayoutModule,
        AdamCommonComponentsModule,
        AngularFireAuthModule,
        AngularFireAuthGuardModule,
        AngularFireModule.initializeApp(environment.firebase),
        HttpClientModule,
    ],
    providers: [
        {provide: 'LOGINURL', useValue: '/login'},
        {provide: LOCALE_ID, useValue: 'nl_NL'},
        TitleService,
        AppInitializerService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializer: AppInitializerService) => () => appInitializer.initializeApp(),
            deps: [AppInitializerService],
            multi: true
        }

    ],
    bootstrap: [PortalAppComponent]
})
export class PortalAppModule {
}

