import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SluitingsPeriode} from "./sluitings.periode";
import {HttpClient} from "@angular/common/http";
import {BASE_URL} from "../../app.constants";

@Injectable({
    providedIn: 'root'
})
export class ClosingPeriodService {

    constructor(private readonly httpClient: HttpClient) {
    }

    public getSluitingsperiodes(storeUuid: string): Observable<Array<SluitingsPeriode>> {
        return this.httpClient.get<Array<SluitingsPeriode>>(BASE_URL + 'portal/stores/' + storeUuid + '/availability');
    }

    public voegSluitingsperiodeToe(storeUuid: string, dto: SluitingsPeriode): Observable<Array<SluitingsPeriode>> {
        return this.httpClient.post<Array<SluitingsPeriode>>(BASE_URL + 'portal/stores/' + storeUuid + '/closing-periods', dto);
    }

    public heeftLopendeBestellingen(storeUuid: string, dto: SluitingsPeriode): Observable<Array<Date>> {
        return this.httpClient.post<Array<Date>>(BASE_URL + 'portal/stores/' + storeUuid + '/closing-periods/pending-deliveries', dto);
    }

    public deleteClosingPeriod(storeUuid: string, closingPeriodId): Observable<any> {
        return this.httpClient.delete<Array<SluitingsPeriode>>(BASE_URL + 'portal/stores/' + storeUuid + '/closing-periods/' + closingPeriodId);
    }


}
