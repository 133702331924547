<p>Ben je je wachtwoord kwijt?<br/><br/>
    Vul hieronder je e-mailadres in. Je ontvangt dan per e-mail instructies om een
    nieuw wachtwoord in te stellen.</p>
<form [formGroup]="resetForm" class="custom-form">
    <mat-form-field class="w-100">
        <input matInput type="email" name="email" placeholder="E-mailadres"
               formControlName="email">
        <mat-error *ngIf=" resetForm.get('email').touched && resetForm.get('email').hasError('email')">
            Dit is geen geldig e-mailadres
        </mat-error>
    </mat-form-field>
    <button type="submit" mat-raised-button color="primary" class="w-100" (click)="nieuwRequest()"
            [disabled]="!resetForm.valid">Instructies opvragen
    </button>

</form>

