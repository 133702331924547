import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";
import {StoreDto} from "./store.dto";

@Injectable({
    providedIn: 'root'
})
export class StoreService {


    constructor(private readonly httpClient: HttpClient) {
    }

    public getStore(uuid: string): Observable<StoreDto> {
        return this.httpClient.get<StoreDto>(BASE_URL + 'portal/stores/' + uuid)
    }

    public getStores(): Observable<Array<StoreDto>> {
        return this.httpClient.get<Array<StoreDto>>(BASE_URL + 'portal/stores')
    }

    public getStoresOfSeller(sellerUuid: string): Observable<Array<StoreDto>> {
        return this.httpClient.get<Array<StoreDto>>(BASE_URL + 'portal/stores?sellerUuid=' + sellerUuid)
    }

    public updateStore(uuid: string, data: any): Observable<any> {
        return this.httpClient.post(BASE_URL + 'portal/stores/' + uuid, data)
    }

    public createStore(data: any): Observable<StoreDto> {
        return this.httpClient.put<StoreDto>(BASE_URL + 'portal/stores', data)
    }

    sendDeliveryMail(storeUuid: string) {
        return this.httpClient.post<any>(`${BASE_URL}portal/stores/${storeUuid}/send-next-delivery-mail`,{});
    }
}
