import {AddressDto} from "../../address/address.dto";

export class ProfileUpdateDto {
    id: number;
    gebruikersnaam: string;
    naam: string;
    address: AddressDto;
    telefoonnummer: string;
    kvk: string
    procesEmail: string;
    factuurEmail: string;
    postcodes: string[];
    betaalAccountCompleet: boolean;
    betaalVerificatieLopend: boolean;
}
