import {AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors} from "@angular/forms";
import {PostcodeService} from "../../services/postcode/postcode.service";

export class PostcodeBestaatValidator {
    static createValidator(postcodeCtrl: AbstractControl, huisnummerCtrl: AbstractControl, postcodeService: PostcodeService): AsyncValidatorFn {
        return (_control: FormGroup): Promise<ValidationErrors> => {
            return new Promise((resolve, _reject) => {
                let postcode = postcodeCtrl.value;
                const huisnummer = huisnummerCtrl.value;
                if (postcode && huisnummer) {
                    postcode = postcode.replace(/ /g, '');
                    postcodeService.getAdresSimpel(postcode, huisnummer).subscribe({
                      next: (result) => {
                          if (!result.bestaat) {
                              resolve({postcodeBestaatNiet: true})
                          } else {
                              resolve(null)
                          }},
                      error: () => {
                        resolve({postcodeServiceError: true})
                      }});
                } else {
                    resolve(null);
                }
            });
        };
    }
}
