import {Injectable} from '@angular/core';
import {concat, mergeMap, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BASE_URL} from "../../app.constants";
import {BestellingDto} from "./bestelling.dto";
import {BestellingAnnuleerRedenDto} from "./bestelling-annuleer-reden.dto";
import {UpcomingDeliveryDto} from "./upcoming-delivery.dto";
import {DeliveriesFilterDto} from "./deliveries-filter.dto";

@Injectable({
    providedIn: 'root'
})
export class BestellingenService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getDeliveries(filter: DeliveriesFilterDto): Observable<Array<BestellingDto>> {
        return this.httpClient.post<Array<BestellingDto>>(`${BASE_URL}portal/deliveries`, filter);
    }

    getBestelling(id: String): Observable<BestellingDto> {
        return this.httpClient.get<BestellingDto>(`${BASE_URL}portal/deliveries/${id}`);
    }
    getAanstaandeBestellingen(storeUuid?: string): Observable<UpcomingDeliveryDto> {
        if(storeUuid){
            return this.httpClient.get<UpcomingDeliveryDto>(`${BASE_URL}portal/deliveries/upcoming?storeUuid=${storeUuid}`);
        }
        return this.httpClient.get<UpcomingDeliveryDto>(`${BASE_URL}portal/upcoming`);
    }


    confirmAssignment(orderId: string): Observable<BestellingDto> {
        return this.httpClient.post<BestellingDto>(`${BASE_URL}portal/deliveries/confirm/${orderId}`, {})
    }

    cancelOrder(orderId: string, reason: string): Observable<BestellingDto> {
        return this.httpClient.post<BestellingDto>(`${BASE_URL}portal/deliveries/${orderId}/cancel`, {reason: reason.toUpperCase()})
    }

    getCancelReasons(): Observable<Array<String>> {
        return this.httpClient.get<Array<String>>(`${BASE_URL}portal/deliveries/cancel-reasons`)
    }
}
