import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";

@Injectable({
    providedIn: 'root'
})
export class ToolsService {
    constructor(private readonly httpClient: HttpClient) {
    }

    getProps(): Observable<any>{
        return this.httpClient.get<any>(`${BASE_URL}portal/tooling/props`);
    }

    sendMonitoring() {
        return this.httpClient.post<any>(`${BASE_URL}portal/tooling/system-state`,{});
    }
}
