import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";

@Injectable({
    providedIn: 'root'
})
export class PackageslipService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getPackageSlip(orderId): Observable<Blob> {
        return this.httpClient.get(`${BASE_URL}portal/deliveries/${orderId}/package-slip`, {responseType: 'blob'});
    }


}
