import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {ConfiguratieService} from '../../services/configuratie/configuratie.service';
import {AdamAuthService} from "../adam-auth.service";

@Component({
    selector: 'adam-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    resetForm: FormGroup;
    @Input()
    buttonText = 'wijzigen'

    hide = true;
    @Output() onFormSubmit = new EventEmitter<any>();

    constructor(private readonly router: Router,
                private readonly configService: ConfiguratieService,
                private readonly authService: AdamAuthService,
                private readonly _formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.resetForm = this._formBuilder.group({
                wachtwoord: [undefined, [Validators.required,
                    Validators.minLength(8),
                    // 2. check whether the entered password has a number
                    patternValidator(/\d/, {hasNumber: true}),
                    // 3. check whether the entered password has upper case letter
                    patternValidator(/[A-Z]/, {hasCapitalCase: true}),
                    // 4. check whether the entered password has a lower-case letter
                    patternValidator(/[a-z]/, {hasSmallCase: true}),]],
                herhaalWachtwoord: [undefined, [Validators.required]]
            },
            {
                validator: ComparePassword("wachtwoord", "herhaalWachtwoord")
            });

    }

    wijzigen() {
        this.resetForm.markAllAsTouched();
        if (this.resetForm.valid) {
            this.onFormSubmit.emit({password: this.resetForm.value.wachtwoord});
        }
    }
}

export function ComparePassword(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }

        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}

export function patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            // if control is empty return no error
            return null;
        }

        // test the value of the control against the regexp supplied
        const valid = regex.test(control.value);

        // if true, return no error (no error), else return error passed in the second parameter
        return valid ? null : error;
    };
}
