import {BehaviorSubject, Observable} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {map} from "rxjs/operators";
import {AlertStatusDto} from "./alert-status.dto";
import {Injectable} from "@angular/core";
import {MAIN_ALERT} from "../../app.constants";

@Injectable({
    providedIn: 'root'
})
export class AlertBarService {

    private readonly statussen = {};
    private readonly statusSubject = new BehaviorSubject<any>(status);

    constructor(private readonly logger: NGXLogger) {
    }

    public getStatus(name: string): Observable<AlertStatusDto> {
        return this.statusSubject.pipe(
            map(it => it[name])
        );
    }

    public registerAlertbar(name: string) {
        if (!this.alertbarExists(name)) {
            this.logger.debug('Nieuwe alertbar: ' + name);
            this.statussen[name] = false;
        }
    }

    public unregisterAlertbar(name: string) {
        if (this.alertbarExists(name)) {
            this.logger.debug('Alertbar verwijderd: ' + name);
            delete this.statussen[name];
        }
    }

    public startAlertbar(name: string, status: AlertStatusDto): void {
        if (!this.alertbarExists(name)) {
            this.registerAlertbar(name);
        }
        this.statussen[name] = status;
        this.logger.debug('Alertbar aan: ' + name);
        this.publish();
    }

    public startMainAlertbar(status: AlertStatusDto): void {
        this.startAlertbar(MAIN_ALERT, status);
    }

    public stopMainAlertbar() {
       this.stopAlertbar(MAIN_ALERT)
    }

    public stopAlertbar(name: string) {
        if (this.alertbarExists(name)) {
            this.statussen[name] = false;
            this.logger.debug('Alertbar uit: ' + name);
            this.publish();
        }
    }

    private publish(): void {
        this.statusSubject.next(this.statussen);
    }

    private alertbarExists(name: string): boolean {
        return Object.prototype.hasOwnProperty.call(this.statussen, name);
    }
}
