import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AdamSpinnerService, AppStatusDto, TitleService} from "adam-common";
import {PortalAppService} from "./portal-app.service";

@Component({
    selector: 'app-root',
    templateUrl: './portal-app.component.html',
    styleUrls: ['./portal-app.component.scss']
})
export class PortalAppComponent implements OnDestroy, OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    status: AppStatusDto;
    SPINNER_NAME = "startSpinner"

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    constructor(private titleService: TitleService,
                private appService: PortalAppService,
                private spinnerService: AdamSpinnerService) {
        this.spinnerService.startSpinner(this.SPINNER_NAME);
    }

    ngOnInit() {
        this.appService.initApp();
        this.titleService.init();
        this.appService.getStatus().subscribe(it => {
            this.status = it;
        })
    }

    probeerOpnieuw(event) {
        event.stopPropagation();
        window.location.reload();

    }
}
