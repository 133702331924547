import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './spinner.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FlexLayoutModule} from "@angular/flex-layout";

@NgModule({
    declarations: [
        SpinnerComponent
    ],
    imports: [CommonModule, FlexLayoutModule, MatProgressSpinnerModule],
    exports: [
        SpinnerComponent,
    ]
})
export class SpinnerModule {

}
