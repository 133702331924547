import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ConfiguratieEnum} from './configuratie.enum';
import {ConfiguratieDto} from './configuratie.dto';
import {BASE_URL} from "../../app.constants";
import {Cacheable} from "ts-cacheable";

@Injectable({
    providedIn: 'root',
})
export class ConfiguratieService {
    private _CONFIGURATIE: ConfiguratieDto[] = [];

    constructor(private readonly http: HttpClient,
                private readonly logger: NGXLogger) {
    }

    public getMandatoryConfig(configuratie: ConfiguratieEnum): string {
        const config = this.getConfig(configuratie);
        if (config) {
            return config;
        }
        throw new Error('Geen configuratie voor: ' + configuratie);
    }

    public getConfig(configuratie: ConfiguratieEnum, defaultValue = null): string {
        const configuratieDto = this._CONFIGURATIE.find((value) => {
            return value.name.toLowerCase() === configuratie.toLowerCase();
        });
        if (configuratieDto) {
            return configuratieDto.value;
        }
        return defaultValue;
    }

    @Cacheable({maxAge: 1000})
    public getConfigRest(): Observable<Array<ConfiguratieDto>> {
        return this.http.get<Array<ConfiguratieDto>>(BASE_URL + 'open/configuratie');
    }

    public loadConfiguratie(): Observable<void> {
        this.logger.debug('Configuratie wordt opgehaald');
        return this.http.get(BASE_URL + 'open/configuratie').pipe(
            map((configuratie: ConfiguratieDto[]) => {
                this.logger.debug('Configuratie is opgehaald: ' + JSON.stringify(configuratie));
                this._CONFIGURATIE = configuratie;
            }),
            catchError((error) => {
                this.logger.error('Fout tijdens het ophalen van de configuratie', error);
                throw error;
            }));
    }
}
