import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {AppStatusDto, ConfiguratieService} from 'adam-common';

@Injectable({
    providedIn: 'root',
})
export class PortalAppService {

    public statusSubject$ = new BehaviorSubject<AppStatusDto>({starting: true, error: false, started: false});

    constructor(private readonly configuratieService: ConfiguratieService,
                private readonly logger: NGXLogger) {
    }

    public getStatus(): Observable<AppStatusDto> {
        return this.statusSubject$.asObservable();
    }

    public initApp(): Promise<boolean> {
        return  this.configuratieService.loadConfiguratie().pipe(
            map((x) => {
                this.next({started: true, error: false, starting: false});
                return true;
            }),
            catchError((e) => {
                if(e.status===401){
                    this.next({started: true, error: false, starting: false});
                    return of(true).toPromise();
                }
                this.logger.error(e);
                this.next({started: false, error: true, starting: false});
                return of(false).toPromise();
            })
        ).toPromise();
    }

    private next(status: AppStatusDto) {
        this.logger.debug('Nieuwe appstatus: ', status);
        this.statusSubject$.next(status);
    }
}
