
export class ProfileDto {
    email: string;
    active: boolean;
    authorities: Array<any>;
    sellerUuid: string;
    seller: boolean;
    employee: boolean;
    admin: boolean;
}
