import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

const postcode6Pattern = /^\d{4} ?[a-z]{2}$/i;
const postcode4Pattern = /^\d{4}$/;

export function valideLeverPostcode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value?.length === 0) {
            return null;
        }
        const valueToTest = control.value.trim();
        const valid =  postcode6Pattern.test(valueToTest) || postcode4Pattern.test(valueToTest);
        return valid ? null : {valideLeverPostcode: {valid: false, value: valueToTest}};
    };
}