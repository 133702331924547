import {Component, Input, OnInit} from '@angular/core';
import {AdamSpinnerService} from './spinner.service';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'adam-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.scss']
})
export class SpinnerComponent implements OnInit {

    constructor(private readonly spinnerService: AdamSpinnerService) {
    }

    @Input()
    private readonly name: string;

    public showSpinner: boolean;

    ngOnInit() {
        this.spinnerService.registerSpinner(this.name);
        this.spinnerService.getStatus(this.name).pipe(untilDestroyed(this)).subscribe((showSpinner) => {
            this.showSpinner = showSpinner;
        });
    }

}
