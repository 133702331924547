import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from '../../app.constants';
import {GebruikerDto} from "./gebruiker.dto";

@Injectable({
    providedIn: 'root'
})
export class GebruikersService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getGebruikers(): Observable<Array<GebruikerDto>> {
        return this.httpClient.get<Array<GebruikerDto>>(`${BASE_URL}portal/users`);
    }

    activeer(gebruikerId: number): Observable<void> {
        return this.httpClient.post<void>(`${BASE_URL}portal/users/${gebruikerId}/activate`, {});
    }

    deactiveer(gebruikerId: number): Observable<void> {
        return this.httpClient.post<void>(`${BASE_URL}portal/users/${gebruikerId}/deactivate`, {});
    }


}
