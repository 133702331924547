import {Component, OnInit} from '@angular/core';
import {ConfiguratieService} from "../../services/configuratie/configuratie.service";
import {ConfiguratieEnum} from "../../services/configuratie/configuratie.enum";

@Component({
    selector: 'adam-when-testmode',
    templateUrl: './adam-when-testmode.component.html',
    styleUrls: ['./adam-when-testmode.component.css']
})
export class AdamWhenTestmodeComponent implements OnInit {

    public testModeEnabled: boolean;

    constructor(private readonly configService: ConfiguratieService) {
        this.testModeEnabled = this.configService.getConfig(ConfiguratieEnum.TEST_MODE, true) === "true";
    }

    ngOnInit(): void {
    }

}
