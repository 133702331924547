import {AddressDto} from "../../address/address.dto";
import {OpeningstijdenDto} from "../openingsdagen/openingstijd.dto";

export class StoreDto {
    name: string;
    uuid: string;
    email: string;
    phonenumber: string;
    sellerUuid: string;
    sellerName: string;
    receiveDeliveries: boolean = false;
    openingdays: OpeningstijdenDto;
    address: AddressDto;
    postalcodes: Array<string>
}

