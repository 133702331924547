export class CommissionDto {
    orderId: string;
    variableCommissionPercentage: string;
    fixedCommissionAmount: string;
    variableCommissionAmount: string;
    potentialCommissionAmount: string;
    realizedCommissionAmount: string;
}




