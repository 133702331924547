import {Component, Input, OnInit} from '@angular/core';
import {OpeningstijdenDto} from "../../services/openingsdagen/openingstijd.dto";

@Component({
    selector: 'adam-openingdays-view',
    templateUrl: './openingdays-view.component.html',
    styleUrls: ['./openingdays-view.component.scss']
})
export class OpeningdaysViewComponent implements OnInit {

    constructor() {
    }

    @Input()
    openingdays: OpeningstijdenDto;

    ngOnInit(): void {
    }

}
