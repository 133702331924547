import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'adam-test-banner',
  templateUrl: './test-banner.component.html',
  styleUrls: ['./test-banner.component.css']
})
export class TestBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
