import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {RequirementsService} from "adam-common";

@Component({
  selector: 'portal-is-payment-account-not-disabled',
  templateUrl: './is-payment-account-not-disabled.component.html'
})
export class IsPaymentAccountNotDisabledComponent implements OnInit {

  public isDisabled: Observable<boolean>;
  constructor(private readonly requirmentService: RequirementsService) {
  }

  ngOnInit(): void {
    this.isDisabled = this.requirmentService.isPaymentAccountDisabled();
  }

}
