import {Component, OnInit} from '@angular/core';
import {ConfiguratieService} from "../../services/configuratie/configuratie.service";
import {ConfiguratieEnum} from "../../services/configuratie/configuratie.enum";

@Component({
    selector: 'adam-app-name',
    templateUrl: './app-name.component.html'
})
export class AppNameComponent implements OnInit {
    public naam: string;

    constructor(private readonly configService: ConfiguratieService) {
    }

    ngOnInit(): void {
        this.naam = this.configService.getConfig(ConfiguratieEnum.APP_NAME)
    }

}
