import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FactuurDto} from "./factuur.dto";
import {BASE_URL} from "../../app.constants";
import {FactuurConceptDto} from "./factuur-concept.dto";

@Injectable({
    providedIn: 'root'
})
export class FacturenService {


    constructor(private readonly httpClient: HttpClient) {
    }

    getFacturen(): Observable<Array<FactuurDto>> {
        return this.httpClient.get<Array<FactuurDto>>(`${BASE_URL}portal/invoices`);
    }

    getInvoice(id: string): Observable<FactuurDto> {
        return this.httpClient.get<FactuurDto>(`${BASE_URL}portal/invoices/${id}`);
    }

    getAdminFacturen(): Observable<Array<FactuurDto>> {
        return this.httpClient.get<Array<FactuurDto>>(`${BASE_URL}portal/invoices`);
    }

    getConcepten(): Observable<Array<FactuurConceptDto>> {
        return this.httpClient.get<Array<FactuurConceptDto>>(`${BASE_URL}portal/invoices/concepts`);
    }

    getPdfUrl(factuurId): Observable<Blob> {
        return this.httpClient.get(`${BASE_URL}/portal/invoices/${factuurId}/pdf`, {responseType: 'blob'});
    }

    getAdminPdfUrl(factuurId): Observable<Blob> {
        return this.httpClient.get(`${BASE_URL}portal/invoices/${factuurId}/pdf`, {responseType: 'blob'});
    }


}
