import { Component, OnInit } from '@angular/core';
import {VersionService} from "../../services/version/version.service";
import {JsonpClientBackend} from "@angular/common/http";

@Component({
  selector: 'portal-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  clickCount = 0;
  showDiv = false;
  versionInfo: any;
  constructor(private versionService: VersionService) {}

  ngOnInit(): void {
    this.versionService.getJsonData().subscribe((data) => {
      this.versionInfo = data;
    });
  }

  incrementClickCount() {
    this.clickCount++;
    if (this.clickCount === 5) {
      this.showDiv = true;

      // Hide the div after 10 seconds
      setTimeout(() => {
        this.showDiv = false;
        this.clickCount = 0; // Reset the click count
      }, 10000);
    }
  }

  protected readonly JsonpClientBackend = JsonpClientBackend;
  protected readonly JSON = JSON;
}



