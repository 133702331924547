import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class AdamSpinnerService {

    private readonly statussen = {};
    private readonly statusSubject = new BehaviorSubject<any>(this.statussen);

    constructor(private readonly logger: NGXLogger) {
    }

    public getStatus(name: string): Observable<boolean> {
        return this.statusSubject.pipe(
            map(it => it[name])
        );
    }

    public registerSpinner(name: string) {
        if (!this.spinnerExists(name)) {
            this.logger.debug('Nieuwe spinner: ' + name);
            this.statussen[name] = false;
        }
    }

    public unregisterSpinner(name: string) {
        if (this.spinnerExists(name)) {
            this.logger.debug('Spinner verwijderd: ' + name);
            delete this.statussen[name];
        }
    }

    public startSpinner(name: string): void {
        if (!this.spinnerExists(name)) {
            this.registerSpinner(name);
        }
        this.statussen[name] = true;
        this.logger.debug('Spinner aan: ' + name);
        this.publish();
    }

    public stopSpinner(name: string) {
        if (!this.spinnerExists(name)) {
            throw new Error('Spinner met naam: ' + name + ' bestaat niet.');
        }
        this.statussen[name] = false;
        this.logger.debug('Spinner uit: ' + name);
        this.publish();
    }

    private publish(): void{
        this.statusSubject.next(this.statussen);
    }

    private spinnerExists(name: string): boolean {
        return Object.prototype.hasOwnProperty.call(this.statussen, name);
    }
}
