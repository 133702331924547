export class EmailDto {
    references: Array<string>;
    uuid: string;
    onderwerp: string;
    naar: string;
    html: string;
    verzonden: boolean;
    accepted: boolean;
    attachments: Array<any>;
}
