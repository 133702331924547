import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BASE_URL} from "../../app.constants";
import {CommissionDto} from "./commission.dto";

@Injectable({
    providedIn: 'root'
})
export class CommissionService {

    constructor(private readonly httpClient: HttpClient) {
    }


    getCommission(id: String): Observable<CommissionDto> {
        return this.httpClient.get<CommissionDto>(`${BASE_URL}portal/deliveries/${id}/commission`);
    }

}
