import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'portal-crud-button',
    templateUrl: './crud-button.component.html',
    styleUrls: ['./crud-button.component.scss']
})
export class CrudButtonComponent implements OnInit {
    icon: string;

    constructor() {
    }

    @Input()
    type: string;

    ngOnInit(): void {
        if(this.type==='refresh'){
            this.icon = "refresh"
        }

    }

}
