import {Injectable} from '@angular/core';
import {AdamAuthService, ProfileService} from "adam-common";

@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {

    constructor(private readonly profileService: ProfileService, private readonly authService: AdamAuthService) {
    }

    initializeApp(): Promise<any> {
        const prom = new Promise((resolve, reject) => {
            this.authService.isLoggedIn().then(isLoggedIn => {
                if (isLoggedIn) {
                    this.profileService.getProfile(true).subscribe(it => {
                        resolve(true);
                    })
                } else {
                    resolve(true);
                }
            })
        });
        return prom;
    }
}
