import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {SellerService} from "./seller.service";
import {mergeMap} from "rxjs/operators";
import {ProfileService} from "../profiel/profile.service";


@Injectable({
    providedIn: 'root'
})
export class CurrentSellerResolver implements Resolve<any> {
    constructor(private sellerService: SellerService, private profileService: ProfileService) {
    }

    resolve(): Observable<any> {
        return this.profileService.getProfile().pipe(
            mergeMap(it => {
                if (it.sellerUuid) {
                    return this.sellerService.getVerkoper(it.sellerUuid);
                }
                throw new Error('No sellerUuid')
            }));
    }
}
