import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {
    BeschikbaarheidService, BestellingenService, ClosingPeriodService,
    ConfiguratieService, FacturenService, GebruikersService,
    Http401Interceptor,
    JwtInterceptor,
    ProfileService, SamenvattingService, SysteemstatusService, SellerService } from 'adam-common';


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class ServicesModule {

    constructor(@Optional() @SkipSelf() parentModule: ServicesModule) {
        if (parentModule) {
            throw new Error(
                'ServicesModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: ServicesModule,
            providers: [
                {provide: 'Window', useValue: window},
                {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
                {provide: HTTP_INTERCEPTORS, useClass: Http401Interceptor, multi: true},
                BestellingenService,
                ConfiguratieService,
                FacturenService,
                GebruikersService,
                ClosingPeriodService,
                SysteemstatusService,
                BeschikbaarheidService,
                ProfileService,
                SamenvattingService,
                SellerService
            ]
        };
    }
}
