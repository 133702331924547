<ng-container *ngIf="status.started">
    <adam-when-testmode>
        <adam-test-banner></adam-test-banner>
    </adam-when-testmode>
    <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="status.starting || status.error">
    <div class="login-page">
        <div class="content px20">
            <div class="login-form">
                <ng-container *ngIf="status.error">
                    <div class="text-center">
                        <h2 class="app-name">
                            Er is een fout opgetreden
                        </h2>
                    </div>
                    <div fxLayout="row">
                        <div fxFlexFill>
                            Probeer het later nog eens<br/><br/>
                            <a href="#" (click)="probeerOpnieuw($event)">Probeer opnieuw</a>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="status.starting">
                    <div class="text-center">
                        <h2 class="app-name">
                            Applicatie is aan het laden
                        </h2>
                    </div>
                    <div fxLayout="row">
                        <div fxFlexFill>
                            <adam-spinner [name]="SPINNER_NAME"></adam-spinner>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
