import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BASE_URL} from "../../app.constants";
import {ProductDto} from "./product.dto";
import {AdminProductDto} from "./admin-product.dto";

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(private readonly httpClient: HttpClient) {
    }

    public getProducten(): Observable<Array<ProductDto>> {
        return this.httpClient.get<Array<ProductDto>>(BASE_URL + 'open/products');
    }

    public getAdminProducten(): Observable<AdminProductDto> {
        return this.httpClient.get<AdminProductDto>(BASE_URL + 'portal/products');
    }

}
