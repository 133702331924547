import {AddressDto} from "../../address/address.dto";

export interface NieuweVerkoperDto {

    name: string;
    email: string;
    cocNumber: string;
    address: AddressDto;
    created: string;
    vatId: string;
}
