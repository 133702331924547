import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {StoreService} from "./store.service";


@Injectable({
    providedIn: 'root'
})
export class StoreResolver implements Resolve<any> {
    constructor(private storeService: StoreService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.storeService.getStore(route.paramMap.get('id'));
    }
}
