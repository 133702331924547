import {FormGroup, ValidationErrors} from "@angular/forms";

export function getFormValidationErrors(form) {
    Object.keys(form.controls).forEach(key => {
        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
                console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
        }
        if (form.controls[key] instanceof FormGroup) {
            const group = (form.controls[key] as FormGroup);
            if(group.errors!=null){
                Object.keys(group.errors).forEach(error => {
                    console.log('Form error: ' +error);
                });
            }
            getFormValidationErrors(form.controls[key])
        }
    });
    if (form instanceof FormGroup) {
        const group = (form as FormGroup);
        if(group.errors!=null){
            Object.keys(group.errors).forEach(error => {
                console.log('Form error ', error);
            });
        }
    }
}

