import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'bestellingStatus'
})
export class BestellingStatusPipe implements PipeTransform {

    private MAPPING = {
        "delivered": "Wordt gefactureerd",
        "cancelled": "Geannuleerd",
        "invoiced": "Wordt uitbetaald",
        "created": "Aangemaakt",
        "paid_out": "Uitbetaald",
        "not_delivered": "Niet geleverd",
        "not_paid": "Nog niet betaald",
        "planned": "Bezorgen",
        "not_confirmed": "Bevestigen nodig",
        "unassigned": "Nog niet toegewezen",
        "cancelled_by_seller": "Geannuleerd door verkoper",
        "cancelled_by_customer": "Geannuleerd door koper",
        "completed": "Afgerond"
    }

    transform(value: String, ...args: unknown[]): unknown {
        if (this.MAPPING[value.toLowerCase()]) {
            const val = this.MAPPING[value.toLowerCase()];
            return val.charAt(0).toUpperCase() + val.toLowerCase().slice(1).replace("_", " ");
        }
        return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1).replace("_", " ");
    }

}
