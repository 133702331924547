import {FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {StoreForm} from "../../../portal/stores/store-form/store.form";

export function minimaal1DagOpenValidator(): ValidatorFn {
    return (formGroup: FormGroup<StoreForm>): ValidationErrors | null => {
        if (formGroup) {
            const days = formGroup.controls['openingdays'];
            const sunday = days.controls['sunday'].value;
            const monday = days.controls['monday'].value;
            const tuesday = days.controls['tuesday'].value;
            const wednesday = days.controls['wednesday'].value;
            const thursday = days.controls['thursday'].value;
            const friday = days.controls['friday'].value;
            const saturday = days.controls['saturday'].value;
            var isEenOpen = sunday || monday || tuesday || wednesday || thursday || friday || saturday;
            return !isEenOpen ? {minimaal1dag: true} : null;
        }
        return null;
    };
}
