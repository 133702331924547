import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ViewPopupDto} from "./view-popup.dto";

@Component({
    selector: 'adam-simple-modal',
    styleUrls: ['./view-popup.component.scss'],
    template: `
        <h1 mat-dialog-title>{{data.title}}</h1>
        <div mat-dialog-content>
            <div class="iframe-wrapper">
                <iframe frameborder="0" style="overflow:hidden;height:100%;width:100%;min-height: 300px;" height="100%" width="100%"
                        [src]="data.src"></iframe>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-button (click)="close()">{{data.button ? data.button : 'Sluiten'}}</button>
        </div>
    `
})
export class ViewPopupComponent implements OnInit {

    @Output() onClose = new EventEmitter<void>();

    constructor(
        public dialogRef: MatDialogRef<ViewPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ViewPopupDto) {
    }

    ngOnInit(): void {
    }

    close() {
        this.onClose.emit();
    }

}
