export class BetaalGegevensDto {
    iban: string;
    accountName: string;
    activationLink: string;
    basic: PaymentProfileBasic
    idVerificationLinks: Array<any>;
    idVerificationNeeded: boolean;
}

export class PaymentProfileBasic{
    uuid: string;
    internalReference: string;
    externalReference: string;
    incomplete: boolean;
    exists: boolean;
    pendingVerification: boolean;
    status: string;
    disabled: boolean;
}
